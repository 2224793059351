/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.47
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LocationCardApiModel } from './LocationCardApiModel';
import {
    LocationCardApiModelFromJSON,
    LocationCardApiModelFromJSONTyped,
    LocationCardApiModelToJSON,
} from './LocationCardApiModel';

/**
 * 
 * @export
 * @interface StockApiModel
 */
export interface StockApiModel {
    /**
     * 
     * @type {LocationCardApiModel}
     * @memberof StockApiModel
     */
    location?: LocationCardApiModel;
    /**
     * 
     * @type {number}
     * @memberof StockApiModel
     */
    stock?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockApiModel
     */
    unit?: string | null;
}

/**
 * Check if a given object implements the StockApiModel interface.
 */
export function instanceOfStockApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StockApiModelFromJSON(json: any): StockApiModel {
    return StockApiModelFromJSONTyped(json, false);
}

export function StockApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': !exists(json, 'location') ? undefined : LocationCardApiModelFromJSON(json['location']),
        'stock': !exists(json, 'stock') ? undefined : json['stock'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
    };
}

export function StockApiModelToJSON(value?: StockApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': LocationCardApiModelToJSON(value.location),
        'stock': value.stock,
        'unit': value.unit,
    };
}

